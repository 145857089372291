import React from 'react';

import './App.css';

import brochardTechLogoGoldSrc from '../../assets/brochard-tech-gold.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={brochardTechLogoGoldSrc} className="App-logo" alt="logo" />
        <p>
          Coming soon
        </p>

        <div className="App-footer">
          <a
            className="App-link"
            href="https://www.linkedin.com/in/cbrochard"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>

          <a
            className="App-link"
            href="https://brostark.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            BroStark
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
